@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap);
.merchant_varient .education-etijjar-page,
.merchant_varient .education-etijjar-page a,
.merchant_varient .education-etijjar-page div,
.merchant_varient .education-etijjar-page h1,
.merchant_varient .education-etijjar-page h2,
.merchant_varient .education-etijjar-page h3,
.merchant_varient .education-etijjar-page h4,
.merchant_varient .education-etijjar-page h5,
.merchant_varient .education-etijjar-page h6,
.merchant_varient .education-etijjar-page p,
.merchant_varient .education-etijjar-page span {
  font-family: Lato, sans-serif !important;
}
.merchant_varient .header-navbar {
  background: rgba(229, 243, 249, 0.7) !important;
  box-shadow: none !important;
}
.merchant_varient .header-navbar .navbar-collapse {
  flex-grow: inherit;
  width: calc(100% - 261px);
}
.merchant_varient .header-navbar .navbar-collapse .navbar-nav {
  margin: 0 auto;
}
.merchant_varient .header-navbar .navbar-nav a {
  font-family: "Lato", sans-serif !important;
  font-weight: 400;
  padding: 0 0 4px 0 !important;
  margin: 0 24px;
  font-size: 18px;
  line-height: 22px;
  color: #17181a;
  position: relative;
}
.merchant_varient .header-navbar .navbar-nav a:first-child {
  margin-left: 0;
}
.merchant_varient .header-navbar .navbar-nav a:last-child {
  margin-right: 0;
}
.merchant_varient .header-navbar .navbar-nav a::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 75%;
  display: block;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: transparent;
}
.merchant_varient .main-sec-wrapper{
  margin-bottom: 0;
}
.merchant_varient .header-navbar .navbar-nav a:hover {
  color: #293965;
}
.merchant_varient .header-navbar .navbar-nav a:hover::after {
  background: #293965;
}
.merchant_varient .head-seacrh-icon {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  background: #fff;
}
.merchant_varient .header-search {
  padding-right: 24px;
  border-right: 1px solid #fff;
  margin-right: 24px;
}
.merchant_varient .signup-btn-div {
  margin-left: 24px;
}
.merchant_varient .signup-btn-div .btn {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: #fff;
  padding: 12px;
  background: #293965;
  border-color: #293965;
  min-width: 150px;
  border-radius: 15px;
  font-family: "Lato", sans-serif !important;
}
.merchant_varient .signup-btn-div .btn:hover {
  background-color: #00b7e1;
  border-color: #00b7e1;
}
.merchant_varient .header-lanugage button.btn {
  background: transparent;
  padding: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  border: none;
  border-radius: 0;
  color: #000000;
  font-family: "Lato", sans-serif !important;
}
.merchant_varient .header-lanugage .dropdown-toggle::after {
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg width='18' height='9' viewBox='0 0 18 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.00002 8.8C8.30002 8.8 7.60002 8.53 7.07002 8L0.55002 1.48C0.26002 1.19 0.26002 0.709999 0.55002 0.419999C0.84002 0.129999 1.32002 0.129999 1.61002 0.419999L8.13002 6.94C8.61002 7.42 9.39002 7.42 9.87002 6.94L16.39 0.419999C16.68 0.129999 17.16 0.129999 17.45 0.419999C17.74 0.709999 17.74 1.19 17.45 1.48L10.93 8C10.4 8.53 9.70002 8.8 9.00002 8.8Z' fill='%23292D32'/%3E%3C/svg%3E%0A");
  width: 18px;
  height: 9px;
  border: none;
  vertical-align: 0.155em;
  margin-left: 8px;
}
.merchant_varient .education-etijjar-page .btn {
  border-radius: 15px;
  background-color: #293965;
  padding: 15px 54px;
  color: #f0f1f5;
  border-color: #293965;
  font-family: "Lato", sans-serif !important;
}
.merchant_varient .education-etijjar-page .btn:hover {
  background-color: #00b7e1;
  border-color: #00b7e1;
}
.merchant_varient .education-etijjar-page .btn:focus-visible {
  box-shadow: none;
  border: none;
}
.merchant_varient .banner-wrap {
  padding: 36px 0 120px;
  position: relative;
  display: flex;
  align-items: center;
}
.merchant_varient .hero-banner-left {
  width: 100%;
  max-width: 376px;
}
.merchant_varient .hero-banner-title {
  max-width: 620px;
  width: 100%;
  text-align: center;
}
.merchant_varient .hero-banner-title h1 {
  max-width: 463px;
  width: 100%;
  margin: 15% auto 0;
  font-weight: 700;
  font-size: 56px;
  line-height: 1.4;
  letter-spacing: -0.35px;
  text-align: center;
}
.merchant_varient .hero-banner-title h1 span {
  color: #293965;
}
.merchant_varient .hero-banner-title p {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #808080;
  margin-bottom: 45px;
}
.merchant_varient .banner-regi-link {
  color: #293965;
  font-size: 20px;
  line-height: 27px;
  text-decoration: none;
  font-weight: 900;
}
.merchant_varient .hero-banner-right {
  position: absolute;
  bottom: 20px;
  right: -10%;
  padding: 0;
  max-width: 742px;
  width: 100%;
}
.merchant_varient .hero-banner-right .video-div {
  position: absolute;
  width: 100%;
  max-width: 484px;
  top: 15px;
  left: 150px;
}
.merchant_varient .hero-banner-right .video-div::before {
  padding-bottom: 66.66%;
  display: block;
  content: "";
  background: #ccc;
}
.merchant_varient .hero-banner-right .video-div video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.merchant_varient .best-offers-section {
  background: linear-gradient(180deg, #293965 0, #022b9a 100%);
  padding: 80px 0;
}
.merchant_varient .best-offers-section::before {
  background: url(../images/pricing-bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  mix-blend-mode: multiply;
  content: "";
  pointer-events: none;
}
.merchant_varient .best-offer-title h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 1.4;
  color: #fff;
  margin-bottom: 40px;
}
.merchant_varient .offer-block {
  border: 3px solid transparent;
  transition: all 0.2s ease;
  border-radius: 20px;
  margin: 15px 0;
  position: relative;
}
.merchant_varient .offer-block-row .col {
  width: 33.33%;
  flex: inherit;
}
.merchant_varient .offer-block-row {
  justify-content: center;
}
.merchant_varient .offer-block-wrap {
  padding: 40px 40px 130px;
  transition: all 0.2s ease;
  border-radius: 20px;
}
.merchant_varient .offer-block.active .offer-block-wrap,
.merchant_varient .offer-block:hover .offer-block-wrap {
  background: rgba(19, 34, 76, 1);
}
.merchant_varient .offer-block.active:before,
.merchant_varient .offer-block:hover:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 20px;
  margin: -3px;
  border-radius: inherit;
  background: linear-gradient(180deg, #1179a2 0, rgba(17, 121, 162, 0) 70%);
}
.merchant_varient .offer-head {
  min-height: 198px;
}
.merchant_varient .offer-head h6 {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #8893b3;
}
.merchant_varient .offer-head h2 {
  font-weight: 800;
  display: flex;
  font-size: 58px;
  line-height: 87px;
  color: #8893b3;
}
.merchant_varient .offer-head h2 span {
  font-weight: 400 !important;
  margin-left: 15px;
  font-size: 30px;
  line-height: 87px;
  color: #8893b3;
}
.merchant_varient .offer-head h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #8893b3;
}
.merchant_varient .offer-head p {
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  color: #8893b3;
}
.merchant_varient .offer-content {
  margin-top: 24px;
  margin-bottom: 66px;
}
.merchant_varient .offer-content h1 span {
  font-weight: 600;
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 28px;
  color: #8893b3;
}
.merchant_varient .offer-content h3 {
  font-weight: 600;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 30px;
  color: #8893b3;
}
.merchant_varient .offer-content .benifit_list {
  max-height: 170px;
  overflow: hidden;
}
.merchant_varient .offer-content ul li,
.merchant_varient .offer-content .benifit_list p,
.merchant_varient .benefits-model .offer-content p {
  font-weight: 400;
  font-weight: 400;
  margin-bottom: 15px !important;
  position: relative;
  font-size: 16px !important;
  padding-left: 28px;
  line-height: 20px !important;
  color: #8893b3 !important;
}
.merchant_varient .offer-content ul li:last-child,
.merchant_varient .offer-content .benifit_list p:last-child,
.merchant_varient .benefits-model .offer-content p:last-child {
  margin-bottom: 0 !important;
}
.merchant_varient .offer-content ul li::before,
.merchant_varient .offer-content .benifit_list p::before,
.merchant_varient .benefits-model .offer-content p::before {
  content: "";
  position: absolute;
  top: 6px;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='19' height='15' viewBox='0 0 19 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.32491 14.5834C5.26995 14.5826 5.21646 14.5654 5.17123 14.5342C5.126 14.5029 5.09104 14.459 5.07079 14.4079C5.05141 14.3691 3.37276 10.0352 0.776712 8.39529C0.269018 8.08304 -0.121302 7.7514 0.0348258 7.00952C0.190954 6.28756 0.678716 5.87731 1.55735 5.68243C3.11863 5.35024 4.79784 7.77078 5.4218 8.76624C7.6087 5.58499 11.9808 0.587778 18.3445 0.00202011C18.6512 -0.0339669 18.7652 0.42113 18.4812 0.548469C18.3838 0.587224 9.28736 4.7451 5.59786 14.4273C5.57028 14.4751 5.53048 14.5148 5.48252 14.5423C5.43456 14.5697 5.38016 14.5839 5.32491 14.5834Z' fill='%231179A2'/%3E%3C/svg%3E%0A");
  display: inline-block;
  height: 15px;
  width: 19px;
  background-repeat: no-repeat;
}

.merchant_varient .benefits-model .offer-content p {
  color: #293965 !important;
}

.merchant_varient .offer-footer {
  position: absolute;
  bottom: 40px;
  width: calc(100% - 80px);
  left: 0;
  right: 0;
  margin: 0 auto;
}
.merchant_varient .offer-footer button.btn.viewmore-button {
  padding: 0;
  background: transparent;
  border-radius: 0;
  border: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #8893b3;
  margin-bottom: 16px;
}
.merchant_varient .offer-footer button.btn.viewmore-button:hover {
  background: transparent;
}
.merchant_varient .offer-footer button.btn {
  transition: all 0.2s ease;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  padding: 14px;
  color: #8893b3;
  background: transparent;
  border-radius: 15px;
  border: 1px solid #8893b3;
}
.merchant_varient .offer-block.active .offer-content h3,
.merchant_varient .offer-block.active .offer-content li,
.merchant_varient .offer-block.active .offer-footer button.btn.viewmore-button,
.merchant_varient .offer-block.active .offer-head h2,
.merchant_varient .offer-block.active .offer-head h2 span,
.merchant_varient .offer-block.active .offer-head h4,
.merchant_varient .offer-block.active .offer-head h6,
.merchant_varient .offer-block.active .offer-head p,
.merchant_varient .offer-block:hover .offer-content h3,
.merchant_varient .offer-block:hover .offer-content li,
.merchant_varient .offer-block:hover .offer-footer button.btn.viewmore-button,
.merchant_varient .offer-block:hover .offer-head h2,
.merchant_varient .offer-block:hover .offer-head h2 span,
.merchant_varient .offer-block:hover .offer-head h4,
.merchant_varient .offer-block:hover .offer-head h6,
.merchant_varient .offer-block:hover .offer-head p {
  color: #fff;
}
.merchant_varient .offer-block.active .offer-content .benifit_list p,
.merchant_varient .offer-block:hover .offer-content .benifit_list p {
  color: #fff !important;
}
.merchant_varient .offer-block.active .offer-footer button.btn,
.merchant_varient .offer-block:hover .offer-footer button.btn {
  background: #fff;
  border-color: #fff;
  color: rgba(41, 57, 101, 1);
}
.merchant_varient .offer-block.active .offer-footer button.btn.viewmore-button,
.merchant_varient .offer-block:hover .offer-footer button.btn.viewmore-button {
  background: transparent;
  color: #fff;
}
.merchant_varient .about-merchant {
  padding: 80px 0;
}
.merchant_varient .about-merchant-title h3 {
  font-weight: 600;
  font-size: 48px;
  line-height: 1.4;
  margin-bottom: 48px;
  text-align: center;
}
.merchant_varient .about-merchant-left {
  max-width: 760px;
  width: 100%;
}
.merchant_varient .about-merchant-right {
  margin-left: auto;
  max-width: 774px;
  width: 100%;
  text-align: center;
}
.merchant_varient .about-merchant-right h2 {
  font-weight: 700;
  font-size: 52px;
  line-height: 1.4;
  margin-bottom: 24px;
  color: #000000;
  letter-spacing: -0.08px;
}
.merchant_varient .about-merchant-right p {
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 48px;
  color: 808080;
}
.merchant_varient footer.site-footer {
  background-color: #091a4a;
  padding: 80px 0;
}
.merchant_varient .footer-logo {
  margin-bottom: 30px;
  max-width: 90%;
}
.merchant_varient .footer-logo-label {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  color: #fff;
  text-align: start;
  font-family: "Lato", sans-serif !important;
}
.merchant_varient .footer-links ul {
  list-style: none;
  padding: 0;
}
.merchant_varient .footer-links .footer-links-col {
  margin: 0 15px;
}
.merchant_varient .footer-links h4,
.merchant_varient .subscribe-us-sec h4 {
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: "Lato", sans-serif !important;
}
.merchant_varient .subscribe-email {
  padding: 16px 17px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  font-size: 16px;
  line-height: 18px;
  color: #b3b3b3;
  border-color: transparent;
  width: 100%;
  font-family: "Lato", sans-serif !important;
}
.merchant_varient .subscribe-email:focus {
  border-color: transparent;
  box-shadow: none;
}
.merchant_varient .footer-mt-submit {
  padding: 13px !important;
  min-width: 108px;
  background-color: #1179a2 !important;
  font-family: "Lato", sans-serif !important;
  min-height: 52px;
}
.merchant_varient .footer-links ul li a {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #848da6;
  margin-bottom: 10px;
  display: inline-block;
  font-family: "Lato", sans-serif !important;
}
.merchant_varient .footer-links ul li a:hover {
  color: #00b7e1;
}
.merchant_varient .footer-social-list li {
  margin-right: 25px;
}
.merchant_varient .footer-form {
  margin-bottom: 30px;
}
.merchant_varient .footer-social-list li:last-child {
  margin-right: 0;
}
.merchant_varient .footer-social-list li a img {
  height: 36px;
}
.merchant_varient .footer-logo-col {
  width: 28%;
  flex: inherit;
}
.merchant_varient .footer-company-link {
  width: 41%;
  flex: inherit;
}
.merchant_varient .footer-subscribe-col {
  width: 31%;
  flex: inherit;
}
.merchant_varient .footer-form .col {
  width: 132px;
}
.merchant_varient .footer-form .col:first-child {
  width: calc(100% - 132px);
}
body[dir="rtl"] .merchant_varient .hero-banner-right {
  right: auto;
  left: -10%;
}
body[dir="rtl"] .merchant_varient .text-start {
  text-align: initial !important;
}
body[dir="rtl"] .merchant_varient .offer-content ul {
  padding: 0;
}
body[dir="rtl"] .merchant_varient .offer-content ul li::before,
body[dir="rtl"] .merchant_varient .offer-content .benifit_list p::before,
body[dir="rtl"] .merchant_varient .benefits-model .offer-content p::before {
  left: auto;
  right: 0;
}
body[dir="rtl"] .merchant_varient .offer-content ul li,
body[dir="rtl"] .merchant_varient .offer-content .benifit_list p,
body[dir="rtl"] .merchant_varient .benefits-model .offer-content p {
  padding-left: 0;
  padding-right: 28px;
}
body[dir="rtl"] .merchant_varient .offer-head h2 span {
  margin-left: 0;
  margin-right: 10px;
}
body[dir="rtl"] .merchant_varient .header-search {
  padding-right: 0;
  padding-left: 24px;
  border-right: none;
  border-left: 1px solid #fff;
  margin-right: 0;
  margin-left: 24px;
}
body[dir="rtl"] .merchant_varient .signup-btn-div {
  margin-left: 0;
  margin-right: 24px;
}
body[dir="rtl"] .merchant_varient .footer-social-list {
  padding: 0;
}
.merchant_varient .benefits-model ul {
  padding-left: 0;
}
.merchant_varient .benefits-model ul li {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  position: relative;
  padding-left: 32px;
  list-style: none;
}
.merchant_varient .benefits-model ul li:last-child {
  margin-bottom: 0;
}
.merchant_varient .benefits-model ul li::before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.32491 19.5834C8.26995 19.5826 8.21646 19.5654 8.17123 19.5342C8.126 19.5029 8.09104 19.459 8.07079 19.4079C8.05141 19.3691 6.37276 15.0352 3.77671 13.3953C3.26902 13.083 2.8787 12.7514 3.03483 12.0095C3.19095 11.2876 3.67872 10.8773 4.55735 10.6824C6.11863 10.3502 7.79784 12.7708 8.4218 13.7662C10.6087 10.585 14.9808 5.58778 21.3445 5.00202C21.6512 4.96603 21.7652 5.42113 21.4812 5.54847C21.3838 5.58722 12.2874 9.7451 8.59786 19.4273C8.57028 19.4751 8.53048 19.5148 8.48252 19.5423C8.43456 19.5697 8.38016 19.5839 8.32491 19.5834Z' fill='%231179A2'/%3E%3C/svg%3E%0A");
  width: 24px;
  height: 24px;
}
.merchant_varient .benefits-model .modal-title {
  color: #293965;
}

@media (min-width: 1600px) {
  .merchant_varient .container {
    max-width: 1520px;
  }
}
@media (max-width: 1599px) {
  .merchant_varient .banner-wrap {
    padding: 30px 0 80px;
  }
  .merchant_varient .hero-banner-right .video-div::before {
    padding-bottom: 66%;
  }
  .merchant_varient .hero-banner-right .video-div {
    max-width: 418px;
    top: 14px;
    left: 130px;
  }
  .merchant_varient .hero-banner-title h1 {
    font-size: 46px;
    margin: 10% auto 0;
  }
  .merchant_varient .hero-banner-title p {
    font-size: 18px;
    line-height: 26px;
  }
  .merchant_varient .hero-banner-right {
    max-width: 640px;
    right: -5%;
    bottom: 40px;
  }
  body[dir="rtl"] .merchant_varient .hero-banner-right {
    right: auto;
    left: -5%;
  }
  body[dir="rtl"] .merchant_varient .hero-banner-title {
    max-width: 520px;
  }
  .merchant_varient .hero-banner-title {
    max-width: 540px;
  }
  .merchant_varient .hero-banner-left {
    max-width: 300px;
  }
  .merchant_varient .about-merchant-right h2 {
    font-size: 46px;
  }
  .merchant_varient .about-merchant-right p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .merchant_varient .footer-links .footer-links-col {
    margin: 0 8px;
  }
}
@media (max-width: 1399px) {
  .merchant_varient .education-etijjar-page .btn {
    padding: 12px 45px;
  }
  .merchant_varient .hero-banner-right .video-div {
    max-width: 332px;
    top: 12px;
    left: 104px;
  }
  .merchant_varient .hero-banner-right .video-div::before {
    padding-bottom: 65%;
  }
  .merchant_varient .header-navbar .navbar-nav a {
    margin: 0 12px;
    font-size: 16px;
    line-height: 22px;
  }
  .merchant_varient .header-lanugage button.btn {
    font-size: 16px;
    line-height: 22px;
  }
  .merchant_varient .signup-btn-div .btn {
    font-size: 16px;
    line-height: 22px;
    padding: 10px;
    min-width: 130px;
  }
  .merchant_varient .head-seacrh-icon {
    height: 44px;
    width: 44px;
  }
  .merchant_varient .header-search {
    padding-right: 18px;
    margin-right: 18px;
  }
  body[dir="rtl"] .merchant_varient .header-search {
    padding-right: 0;
    margin-right: 0;
    padding-left: 18px;
    margin-left: 18px;
  }
  .merchant_varient .signup-btn-div {
    margin-left: 18px;
  }
  body[dir="rtl"] .merchant_varient .signup-btn-div {
    margin-left: 0;
    margin-right: 18px;
  }
  .merchant_varient .about-merchant,
  .merchant_varient .best-offers-section,
  .merchant_varient .site-footer {
    padding: 60px 0;
  }
  .merchant_varient .hero-banner-title h1 {
    font-size: 40px;
    margin: 8% auto 0;
  }
  .merchant_varient .hero-banner-title p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .merchant_varient .hero-banner-right {
    max-width: 510px;
    right: -5%;
    bottom: 30px;
  }
  body[dir="rtl"] .merchant_varient .hero-banner-title {
    max-width: 480px;
  }
  body[dir="rtl"] .merchant_varient .hero-banner-right {
    right: auto;
    left: -5%;
  }

  body[dir="rtl"] .merchant_varient .modal-header .btn-close {
    margin: 0 auto 0 0;
  }

  .merchant_varient .hero-banner-title {
    max-width: 500px;
  }
  .merchant_varient .hero-banner-left {
    max-width: 260px;
  }
  .merchant_varient .banner-wrap {
    padding: 30px 0 70px;
  }
  .merchant_varient .offer-block-wrap {
    padding: 30px 30px 130px;
  }
  .merchant_varient .offer-head p {
    font-size: 14px;
  }
  .merchant_varient .about-merchant-right h2 {
    font-size: 38px;
  }
  .merchant_varient .about-merchant-title h3,
  .merchant_varient .best-offer-title h2 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .merchant_varient .footer-links .footer-links-col {
    margin: 0;
  }
  .merchant_varient .footer-links ul li a {
    font-size: 16px;
    line-height: 28px;
  }
  .merchant_varient .footer-links h4,
  .merchant_varient .subscribe-us-sec h4 {
    font-size: 18px;
    line-height: 26px;
  }
  .merchant_varient .footer-logo-label {
    font-size: 18px;
    line-height: 26px;
  }
  .merchant_varient .footer-social-list li a img {
    height: 32px;
  }
  .merchant_varient .footer-social-list li {
    margin-right: 15px;
  }
}
@media (max-width: 1199px) {
  .merchant_varient .header-navbar.navbar-expand-lg .navbar-toggler {
    display: block;
    padding: 4px;
  }
  .merchant_varient .header-navbar .collapse:not(.show) {
    display: none !important;
  }
  .merchant_varient .hero-banner-right .video-div {
    max-width: 470px;
    top: 18px;
    left: 146px;
  }
  .merchant_varient .hero-banner-right .video-div::before {
    padding-bottom: 64%;
  }
  .merchant_varient .header-navbar .navbar-collapse {
    flex-basis: 100%;
  }
  .merchant_varient .banner-wrap {
    padding: 30px 0 30px;
  }
  .merchant_varient .hero-banner-left {
    width: 30%;
    display: none;
    max-width: 100%;
    flex: inherit;
  }
  .merchant_varient .hero-banner-title {
    width: 100%;
    max-width: 100%;
    flex: inherit;
  }
  body[dir="rtl"] .merchant_varient .hero-banner-title {
    max-width: 100%;
  }
  .merchant_varient .hero-banner-right {
    flex: inherit;
    right: 0;
    position: relative;
    bottom: auto;
    width: 100%;
    max-width: 75%;
    max-width: 720px;
    margin: 30px auto 0;
  }
  body[dir="rtl"] .merchant_varient .hero-banner-right {
    right: auto;
    left: auto;
  }
  .merchant_varient .hero-banner-title h1 br {
    display: none;
  }
  .merchant_varient .hero-banner-title h1 {
    margin: 3% auto 0;
  }
  .merchant_varient .offer-head h6 {
    font-size: 18px;
    line-height: 24px;
  }
  .merchant_varient .offer-head h2 {
    font-size: 46px;
    line-height: 72px;
  }
  .merchant_varient .offer-head h2 span {
    font-size: 22px;
    line-height: 72px;
  }
  .merchant_varient .offer-head h4 {
    font-size: 20px;
    line-height: 32px;
  }
  .merchant_varient .offer-content h3 {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 26px;
  }
  .merchant_varient .offer-content ul li,
  .merchant_varient .offer-content .benifit_list p,
  .merchant_varient .benefits-model .offer-content p  {
    margin-bottom: 10px !important;
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .merchant_varient .offer-content ul li:last-child,
  .merchant_varient .offer-content .benifit_list p:last-child,
  .merchant_varient .benefits-model .offer-content p:last-child {
    margin-bottom: 0 !important;
  }

  .merchant_varient .offer-footer button.btn,
  .merchant_varient .offer-footer button.btn.viewmore-button {
    font-size: 16px;
  }
  .merchant_varient .offer-footer button.btn {
    padding: 10px;
  }
  .merchant_varient .offer-footer {
    bottom: 30px;
    width: calc(100% - 60px);
  }
  .merchant_varient .offer-content {
    margin-top: 20px;
    margin-bottom: 50px;
  }
  .merchant_varient .about-merchant-title h3,
  .merchant_varient .best-offer-title h2 {
    font-size: 36px;
  }
  .merchant_varient .about-merchant-right h2 {
    font-size: 32px;
    margin-bottom: 15px;
  }
  .merchant_varient .footer-logo-col {
    width: 40%;
    flex: inherit;
  }
  .merchant_varient .footer-company-link {
    width: 60%;
    flex: inherit;
  }
  .merchant_varient .footer-subscribe-col {
    margin-top: 30px;
    width: 100%;
    flex: inherit;
  }
  .merchant_varient .footer-logo {
    margin-bottom: 20px;
  }
  .merchant_varient .footer-links ul li a {
    margin-bottom: 5px;
  }
  .merchant_varient footer.site-footer {
    padding: 60px 0;
  }
  .merchant_varient .about-merchant,
  .merchant_varient .best-offers-section,
  .merchant_varient .site-footer {
    padding: 50px 0;
  }
  .merchant_varient .header-navbar .navbar-nav a {
    margin: 0;
    padding: 4px 0 4px 0 !important;
  }
  .merchant_varient .header-seacrh-lang-signup {
    margin-top: 15px;
  }
  .merchant_varient .header-navbar.navbar > .container {
    flex-wrap: wrap;
  }
  .merchant_varient .header-navbar .navbar-collapse .navbar-nav {
    margin: 0;
    width: 100%;
    flex-direction: column;
  }
  .merchant_varient .header-navbar .navbar-collapse {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  .merchant_varient .about-merchant-ctn .col-md-6 {
    width: 100%;
  }
  .merchant_varient .about-merchant-right {
    max-width: 100%;
    margin-top: 30px;
  }
  .merchant_varient .offer-block {
    margin: 10px 0;
  }
  .merchant_varient .offer-block-row .col {
    width: 50%;
  }

  .merchant_varient .offer-content .benifit_list {
    max-height: 200px;
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  .merchant_varient .hero-banner-title h1 {
    font-size: 36px;
  }
  .merchant_varient .hero-banner-right {
    max-width: 540px;
  }
  .merchant_varient .hero-banner-right .video-div {
    max-width: 354px;
    top: 12px;
    left: 109px;
  }
  .merchant_varient .offer-block-wrap {
    padding: 20px 20px 130px;
  }
  .merchant_varient .footer-company-link,
  .merchant_varient .footer-logo-col {
    width: 100%;
    flex: inherit;
  }
  .merchant_varient .footer-links .justify-content-center {
    justify-content: space-between !important;
    margin-top: 20px;
  }
  .merchant_varient footer.site-footer {
    padding: 40px 0;
  }
  .merchant_varient .banner-regi-link {
    font-size: 16px;
    line-height: 20px;
  }
  .merchant_varient .footer-logo{
    max-width: 250px;
    margin-bottom: 15px;
  }
}
@media (max-width: 480px) {
  .merchant_varient .banner-wrap {
    padding: 20px 0 30px;
  }
  .merchant_varient .hero-banner-title h1 {
    font-size: 30px;
  }
  .merchant_varient .education-etijjar-page .btn {
    padding: 8px 30px;
  }
  .merchant_varient .about-merchant,
  .merchant_varient .best-offers-section,
  .merchant_varient .site-footer {
    padding: 40px 0;
  }
  .merchant_varient .about-merchant-title h3,
  .merchant_varient .best-offer-title h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }
  .merchant_varient .offer-head {
    min-height: inherit;
  }
  .merchant_varient .about-merchant-right h2 {
    font-size: 28px;
  }
  .merchant_varient .about-merchant-right p {
    font-size: 16px;
    margin-bottom: 25px;
    line-height: 24px;
  }
  .merchant_varient .footer-links ul li a {
    font-size: 14px;
    line-height: 22px;
  }
  .merchant_varient .footer-links h4,
  .merchant_varient .subscribe-us-sec h4 {
    font-size: 16px;
    line-height: 24px;
  }
  .merchant_varient .hero-banner-right {
    max-width: 375px;
  }
  .merchant_varient .hero-banner-right .video-div {
    max-width: 245px;
    top: 10px;
    left: 76px;
  }
  .merchant_varient .offer-block-row .col {
    width: 100%;
  }
}
@media (max-width: 360px) {
  .merchant_varient .hero-banner-right {
    max-width: 320px;
  }
  .merchant_varient .hero-banner-right .video-div {
    max-width: 209px;
    top: 8px;
    left: 65px;
  }
  .merchant_varient .navbar-brand img {
    height: 50px;
  }
}
